<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{ name: 'account', params: { id: $route.params.id } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
            <span>Account</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>
    <hr class="is-marginless" />
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <div class="buttons are-medium is-marginless">
          <router-link
            v-if="
              fullAccount($route.params.id) &&
                dueValue($route.params.id) > 0 &&
                (pmtDispositionValue($route.params.id) == 'M' ||
                  pmtDispositionValue($route.params.id) == 'S' ||
                  pmtDispositionValue($route.params.id) == 'P' ||
                  pmtDispositionValue($route.params.id) == 'N')
            "
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
            tag="button"
            class="button is-fullwidth has-background-grey-lighter"
            >Make Payment</router-link
          >
          <router-link
            :to="{
              name: 'transactionHistory',
              params: { id: $route.params.id }
            }"
            tag="button"
            class="button is-fullwidth has-background-grey-lighter"
            >See Payments</router-link
          >
          <router-link
            :to="{ name: 'details', params: { id: $route.params.id } }"
            tag="button"
            class="button is-fullwidth has-background-grey-lighter is-hidden-tablet"
            >Account Details</router-link
          >
        </div>
        <account-tags />
        <hr />
        <payment-arrangements :id="$route.params.id" />

        <div class="buttons">
          <button class="button" @click="gotoHome">Back</button>
        </div>
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import { mapGetters, mapActions } from "vuex";
import PaymentArrangements from "@/components/PaymentArrangements.vue";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";
import AccountTags from "@/components/AccountTags.vue";

export default {
  components: {
    BaseLayout,
    AccountHeader,
    UserInfo,
    PaymentArrangements,
    Sidebar,
    AccountTags
  },
  computed: {
    ...mapGetters("accounts", [
      "fullAccount",
      "dueValue",
      "pmtDispositionValue"
    ])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    gotoHome() {
      this.$router.push({ name: "home" });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        vm.$store.dispatch("fetchUserInfo");
      }
      if (
        vm.$store.state.accounts.accounts == null ||
        vm.$store.state.accounts.accounts.length == 0
      ) {
        vm.$store.dispatch("accounts/fetchAccounts");
      }
    });
  }
};
</script>
